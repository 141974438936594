import React from 'react';
import { Container, Section, Wrapper } from 'components/Layout';
import { Image, Carousel } from 'components/Elements';
import { loop } from 'helpers';
import './Slider.scss';

export const Slider = ({ data, items }) => (
  <Section className="slider">
    <Wrapper>
      <Carousel buttonsDisabled dotsDisabled>
        {loop(items, item => (
          <div key={item.key} className="slider-item">
            <div className="slider-content-wrapper">
              <div className="slider-content">
                {item.title && item.title.text && <p className="slider-title">{item.title && item.title.text}</p>}
                {item.description && item.description.html && (
                  <div
                    className="slider-description"
                    dangerouslySetInnerHTML={{ __html: item.description && item.description.html }}
                  />
                )}
              </div>
            </div>

            <div>{item.slider_image && <Image image={item.slider_image} />}</div>
          </div>
        ))}
      </Carousel>
    </Wrapper>
  </Section>
);
