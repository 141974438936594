import React from 'react';
import { Container, Section, Wrapper } from 'components/Layout';
import './Features.scss';

// List Vouchers
const Items = ({ items }) =>
  items.map((item, index) => {
    const { title, subtitle } = item;
    return (
      <div key={index} className="feature">
        <div className="feature-title" dangerouslySetInnerHTML={{ __html: title.html }} />
        <div className="feature-subtitle" dangerouslySetInnerHTML={{ __html: subtitle.html }} />
      </div>
    );
  });

export const Features = ({ data, items }) => {
  const { title, subtitle } = data;
  return (
    <Section className="features">
      <Wrapper>
        <div className="features-title" dangerouslySetInnerHTML={{ __html: title.html }} />
        <div className="features-subtitle" dangerouslySetInnerHTML={{ __html: subtitle.html }} />
        <Container>
          <Items items={items} />
        </Container>
      </Wrapper>
    </Section>
  );
};
