import React, { Component } from 'react';
import axios from 'axios';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';
import { classNames } from 'helpers';
import { TextField, TextArea } from 'components/Form';
import { Wrapper } from 'components/Layout';
import { Icon, Heading, Notification } from 'components/Elements';
import { Coupon } from '../Coupon/Coupon';
import './VouchersCheckout.scss';

class Checkout extends Component {
  state = {
    status: { code: '', message: '' },
    error: '',
    qrCode: '',
    transactionId: null,
    fields: {
      name: '',
      email: '',
      recipientName: '',
      recipientEmail: '',
      recipientMessage: '',
    },
    isGift: false,
  };

  // SetStatus
  setStatus = (code, message) => {
    this.setState({ status: { code, message } });
  };

  // Handle Card change
  handleCardChange = event => {
    const { error } = event;
    if (!error) this.setState({ error: '' });
    if (error) this.setState({ error: error.message });
  };

  // Handle fields change
  handleFieldChange = (name, value) => {
    const { fields } = this.state;
    this.setState({ fields: { ...fields, [name]: value } });
  };

  // Handle checkout form submit
  handleSubmit = async event => {
    try {
      event.preventDefault();
      const { stripe, amount, description } = this.props;
      const { fields, isGift } = this.state;
      if (!stripe) throw new Error("Stripe.js hasn't loaded yet.");
      this.setState({ status: { code: 'waiting' } });
      const { token } = await stripe.createToken({ ...fields });
      const chargeToken = JSON.stringify({ token, amount, description, ...fields, isGift });
      const { data } = await axios.post('/.netlify/functions/pay', chargeToken);
      this.setState({ status: { code: 'success', message: data.message }, ...data });
    } catch (error) {
      this.setState({ status: { code: 'error', message: error.response.data } });
    }
  };

  render() {
    const { status, error, isGift, fields } = this.state;
    const { amount, setStep } = this.props;
    const style = { base: { fontSize: '16px' } };
    const name = isGift ? fields && fields.recipientName : fields && fields.name;
    const email = isGift ? fields && fields.recipientEmail : fields && fields.email;
    const dataObject = { ...this.state, fields: { ...this.state.fields, name, email } };
    const placeholders = { name: 'Jane Doe', email: 'jane.doe@email.com' };
    return (
      <div className="vouchers-checkout">
        <Wrapper>
          <Coupon {...dataObject} placeholders={placeholders} amount={amount} setStatus={this.setStatus} />
          <Notification code={status.code} message={status.message} setStatus={() => this.setState({ status: {} })} />

          <div className={classNames('voucher-checkout-form', status.code)}>
            <Heading title="Checkout" icon="checkout" />

            <form onSubmit={this.handleSubmit.bind(this)}>
              <div className="vouchers-checkout-contact">
                <TextField
                  name="name"
                  label="Name on card"
                  placeholder={placeholders.name}
                  onChange={this.handleFieldChange}
                />
                <TextField
                  name="email"
                  label="Email"
                  placeholder={placeholders.email}
                  onChange={this.handleFieldChange}
                />
              </div>

              <div className={classNames('voucher-checkout-recipient', { show: isGift })}>
                <button type="button" className="recipient-checkbox" onClick={() => this.setState({ isGift: !isGift })}>
                  Is this a gift ?
                </button>
                <div className="recipient-wrapper">
                  <div className="recipient-contact">
                    <TextField
                      name="recipientName"
                      label="Recipient name"
                      placeholder={placeholders.name}
                      onChange={this.handleFieldChange}
                    />
                    <TextField
                      name="recipientEmail"
                      label="Recipient email"
                      placeholder={placeholders.email}
                      onChange={this.handleFieldChange}
                    />
                  </div>
                  <div className="recipient-message">
                    <TextArea
                      name="recipientMessage"
                      label="Your message"
                      placeholder="..."
                      onChange={this.handleFieldChange}
                    />
                  </div>
                </div>
              </div>

              <div className="vouchers-checkout-card">
                <span>Card Information</span>
                <div className={classNames('vouchers-checkout-card-wrapper', { error })}>
                  <div className="card-number">
                    <CardNumberElement style={style} onChange={this.handleCardChange} />
                  </div>
                  <div className="card-details">
                    <div className="card-exiry">
                      <CardExpiryElement style={style} onChange={this.handleCardChange} />
                    </div>
                    <div className="card-cvc">
                      <CardCVCElement style={style} onChange={this.handleCardChange} />
                    </div>
                  </div>
                </div>
                <div className="vouchers-checkout-card-error">{error}</div>
              </div>

              <div className="vouchers-checkout-action">
                <button className="vouchers-checkout-previous" type="button" onClick={() => setStep(0)}>
                  <span>&larr;</span>Previous
                </button>
                <button className={classNames('vouchers-checkout-button', status.code)} disabled={error}>
                  <Icon className="loader" title="loader" />
                  <span className="pay">{`Pay A$${amount / 100}`}</span>
                </button>
              </div>
            </form>
          </div>
        </Wrapper>
      </div>
    );
  }
}

const CheckoutForm = injectStripe(Checkout);

// Wrapper Stripe around CheckoutForm component
export class VouchersCheckout extends Component {Y
  state = { stripe: null };

  componentDidMount() {
    this.setState({ stripe: window.Stripe(process.env.STRIPE_PUBLISHABLE_KEY) });
  }

  render() {
    if (typeof window !== 'undefined') {
      return (
        <StripeProvider apiKey={process.env.STRIPE_PUBLISHABLE_KEY}>
          <Elements>
            <CheckoutForm {...this.props} />
          </Elements>
        </StripeProvider>
      );
    }
    return null;
  }
}
