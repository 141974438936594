import React from 'react';
import { Container, Wrapper } from 'components/Layout';
import { Icon, Heading } from 'components/Elements';
import { TextField } from 'components/Form';
import { classNames } from 'helpers';
import './VouchersSelect.scss';

export const VouchersSelect = ({ items, voucher, custom, setVoucher, setStep }) => {
  // List Vouchers
  const vouchers = items.map((item, index) => {
    const { price } = item;
    const classes = classNames('voucher-item', { active: voucher.index === index });
    return (
      <div key={index} className={classes} onClick={() => setVoucher({ amount: price * 100, index })}>
        {`$${price}`}
      </div>
    );
  });

  // Custom Voucher
  if (custom === 'Yes') {
    const classes = classNames('voucher-item-input', { active: voucher.index === 'custom' });
    const CustomVoucher = (
      <div key="custom" className={classNames('voucher-custom', { active: voucher.index === 'custom' })}>
        <span className="voucher-custom-label">Custom amount</span>
        <TextField
          className={classes}
          onFocus={(name, value) => setVoucher({ amount: value * 100, index: 'custom' })}
          onChange={(name, value) => setVoucher({ amount: value * 100, index: 'custom' })}
          type="number"
          placeholder="$0.00"
        />
      </div>
    );
    vouchers.push(CustomVoucher);
  }

  // Return Vouchers
  return (
    <div className="vouchers-select">
      <Wrapper>
        <Heading title="Select a voucher" icon="select" />
        <Container>{vouchers}</Container>
        <div className="vouchers-select-action">
          <button onClick={() => setStep(1)} disabled={!voucher.amount && voucher.amount < 1}>
            Next
            <span>&rarr;</span>
          </button>
        </div>
      </Wrapper>
    </div>
  );
};
