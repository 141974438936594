import React from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { getPreviewData } from 'helpers';
import { Page } from 'components/Layout';
import { Slices } from '../slices/slices';

export const TemplatePage = ({ location, data: staticData, pageContext }) => {
  const { dataObject } = mergePrismicPreviewData({ staticData, previewData: getPreviewData() });
  const { uid } = pageContext;
  const { body, ...seoData } = dataObject.data || {};
  return (
    <Page uid={uid} location={location} seoData={seoData}>
      <Slices data={body} location={location} />
    </Page>
  );
};

export default TemplatePage;

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    dataObject: prismicPage(uid: { eq: $uid }) {
      data {
        title {
          html
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        body {
          ...PageVoucher
          ...PageSlider
          # ...PageHeading
          # ...PageFeatures
        }
      }
    }
  }
`;
