import React, { useState } from 'react';
import { classNames } from 'helpers';
import { Section, Wrapper } from 'components/Layout';
import { Image } from 'components/Elements';
import { VouchersCheckout } from './VouchersCheckout/VouchersCheckout';
import { VouchersSelect } from './VouchersSelect/VouchersSelect';
import { VouchersVerify } from './VouchersVerify/VouchersVerify';
import './Vouchers.scss';

export const Voucher = ({ data, items, location }) => {
  const [voucher, setVoucher] = useState({ amount: null, index: null });
  const [step, setStep] = useState(0);
  const { title, subtitle, voucher_image: image, custom } = data;
  const classesProcces = classNames('vouchers-process-wrapper', { slide: step === 1 });
  const verificationId = location.search.split('voucher=')[1];

  // Buy Voucher components
  const VouchersBuy = (
    <Section className="vouchers">
      <Wrapper>
        <div className="vouchers-heading">
          <div className="vouchers-image">
            <Image image={image} />
          </div>
          {step === 0 && (
            <>
              <div className="vouchers-title" dangerouslySetInnerHTML={{ __html: title.html }} />
              <div className="vouchers-subtitle" dangerouslySetInnerHTML={{ __html: subtitle.html }} />
            </>
          )}
        </div>
      </Wrapper>
      <div className="vouchers-process">
        <Wrapper className={classesProcces}>
          <VouchersSelect voucher={voucher} items={items} custom={custom} setVoucher={setVoucher} setStep={setStep} />
          <VouchersCheckout amount={voucher.amount} setStep={setStep} />
        </Wrapper>
      </div>
    </Section>
  );

  // Verify Voucher components
  const VoucherVerify = (
    <Section className="vouchers">
      <Wrapper>
        <div className="vouchers-heading">
          <div className="vouchers-title" dangerouslySetInnerHTML={{ __html: title.html }} />
          <div className="vouchers-subtitle" dangerouslySetInnerHTML={{ __html: subtitle.html }} />
        </div>
      </Wrapper>
      <div className="vouchers-verify">
        <Wrapper className={classesProcces}>
          <VouchersVerify transactionId={verificationId} />
        </Wrapper>
      </div>
    </Section>
  );

  return verificationId ? VoucherVerify : VouchersBuy;
};
